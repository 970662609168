:root {
  --font-sans: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-mono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  --primary-color: #3f51b5;
  --secondary-color: #ffeb3b;
  --background-color: #e0f7fa; /* Example gradient background */
  --text-color: #333;
}

body {
  margin: 0;
  font-family: var(--font-sans);
  background: linear-gradient(to bottom, var(--background-color), #fff);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--font-mono);
}

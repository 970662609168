@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&family=Roboto:wght@300;400;700&display=swap');

:root {
  --color-background: #0a0a0a;
  --color-primary: #00ffff;
  --color-secondary: #ff00ff;
  --color-tertiary: #ffff00;
  --color-text: #ffffff;
  --font-display: 'Orbitron', sans-serif;
  --font-body: 'Roboto', sans-serif;
}

@keyframes neon-pulse {
  0%,
  100% {
    text-shadow: 0 0 5px var(--color-primary), 0 0 10px var(--color-primary), 0 0 20px var(--color-primary), 0 0 40px var(--color-primary);
  }
  50% {
    text-shadow: 0 0 10px var(--color-primary), 0 0 20px var(--color-primary), 0 0 40px var(--color-primary), 0 0 80px var(--color-primary);
  }
}

@keyframes background-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-body);
  background: linear-gradient(45deg, #000000, #1a1a1a);
  background-size: 400% 400%;
  animation: background-shift 15s ease infinite;
  color: var(--color-text);
  overflow-x: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header Styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(10, 10, 10, 0.8);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid var(--color-primary);
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.header__logo {
  font-family: var(--font-display);
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-primary);
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: neon-pulse 1.5s infinite alternate;
}

.header__nav {
  display: flex;
  gap: 30px;
}

.header__nav-link {
  color: var(--color-text);
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding: 5px 0;
}

.header__nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--color-secondary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.header__nav-link:hover::after {
  transform: scaleX(1);
}

.header__sign-in {
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  color: var(--color-background);
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.header__sign-in:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 255, 255, 0.3);
}

/* Main Content Area */
.main-content {
  margin-top: 100px;
  position: relative;
}

.main-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, transparent 0%, rgba(0, 255, 255, 0.1) 100%);
  z-index: -1;
}

.topic-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.topic-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 255, 255, 0.3);
}

.topic-card__title {
  font-family: var(--font-display);
  font-size: 2.5rem;
  color: var(--color-primary);
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.topic-card__description {
  font-size: 1.2rem;
  color: var(--color-text);
  opacity: 0.9;
  line-height: 1.6;
}

/* Game Interface Styles */

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.mt-rushmore {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.3);
  transition: all 0.5s ease;
}



.mt-rushmore::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(0, 255, 255, 0.2), rgba(255, 0, 255, 0.2));
  z-index: 1;
}

.mt-rushmore:hover {
  transform: scale(1.02);
  box-shadow: 0 0 50px rgba(0, 255, 255, 0.5);
}

.face-slot {
  position: absolute;
  width: 20%;
  height: 30%;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  z-index: 2;
  transition: all 0.3s ease;
}

.face-slot:nth-child(1) {
  top: 20%;
  left: 10%;
}

.face-slot:nth-child(2) {
  top: 15%;
  left: 35%;
}

.face-slot:nth-child(3) {
  top: 18%;
  right: 35%;
}

.face-slot:nth-child(4) {
  top: 22%;
  right: 10%;
}

.face-slot:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px var(--color-primary);
}

.answer-form {
  margin-top: 30px;
  width: 100%;
  max-width: 600px;
}

.answer-input {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--color-primary);
  border-radius: 25px;
  color: var(--color-text);
  font-size: 1rem;
  transition: all 0.3s ease;
}

.answer-input:focus {
  outline: none;
  box-shadow: 0 0 15px var(--color-primary);
}

.submit-button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  border: none;
  border-radius: 25px;
  color: var(--color-background);
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 255, 255, 0.3);
}

.popular-answers {
  margin-top: 50px;
  width: 100%;
  max-width: 800px;
}

.popular-answers__title {
  font-family: var(--font-display);
  font-size: 2rem;
  color: var(--color-tertiary);
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.popular-answer {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.popular-answer:hover {
  transform: translateX(10px);
  box-shadow: -5px 0 15px rgba(0, 255, 255, 0.3);
}

.popular-answer__text {
  font-size: 1.2rem;
  color: var(--color-text);
}

.popular-answer__count {
  font-family: var(--font-display);
  font-size: 1.5rem;
  color: var(--color-primary);
}

@keyframes countdown {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 314;
  }
}

.timer {
  position: fixed;
  top: 100px;
  right: 30px;
  width: 80px;
  height: 80px;
}

.timer__svg {
  transform: rotate(-90deg);
}

.timer__circle {
  fill: none;
  stroke: var(--color-primary);
  stroke-width: 8;
  stroke-dasharray: 314;
  stroke-dashoffset: 0;
  animation: countdown 60s linear forwards;
}

.timer__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--font-display);
  font-size: 1.5rem;
  color: var(--color-primary);
}

.footer {
  position: relative;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-top: 1px solid var(--color-primary);
  box-shadow: none;
  transition: none;
}

.footer__text {
  font-size: 1rem;
  color: var(--color-text);
  transform: none;
  animation: none;
  transition: none;
  text-shadow: none;
}

.footer__text,
.achievement,
.popular-answers__title {
  animation: none;
  transform: none;
  transition: none;
}

/* Advanced Animations and Responsive Design */

@keyframes glitch {
  0% {
    clip-path: inset(50% 0 30% 0);
    transform: translate(-5px, 5px);
  }
  20% {
    clip-path: inset(15% 0 65% 0);
    transform: translate(5px, -5px);
  }
  40% {
    clip-path: inset(80% 0 5% 0);
    transform: translate(-5px, 5px);
  }
  60% {
    clip-path: inset(40% 0 43% 0);
    transform: translate(5px, -5px);
  }
  80% {
    clip-path: inset(25% 0 60% 0);
    transform: translate(-5px, 5px);
  }
  100% {
    clip-path: inset(50% 0 30% 0);
    transform: translate(5px, -5px);
  }
}

.glitch-effect {
  position: relative;
}

.glitch-effect::before,
.glitch-effect::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch-effect::before {
  left: 2px;
  text-shadow: -2px 0 var(--color-secondary);
  animation: glitch 0.3s infinite linear alternate-reverse;
}

.glitch-effect::after {
  left: -2px;
  text-shadow: 2px 0 var(--color-tertiary);
  animation: glitch 0.3s infinite linear alternate;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 255, 0.1), 0 0 0 20px rgba(0, 255, 255, 0.1), 0 0 0 40px rgba(0, 255, 255, 0.1), 0 0 0 60px rgba(0, 255, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 255, 255, 0.1), 0 0 0 40px rgba(0, 255, 255, 0.1), 0 0 0 60px rgba(0, 255, 255, 0.1), 0 0 0 80px rgba(0, 255, 255, 0);
  }
}

.ripple-button {
  position: relative;
  overflow: hidden;
}

.ripple-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.ripple-button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

.game-progress {
  width: 100%;
  height: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
}

.game-progress__bar {
  width: 0;
  height: 100%;
  background: linear-gradient(90deg, var(--color-primary), var(--color-secondary));
  transition: width 0.5s ease;
}

@media (max-width: 768px) {
  .header__content {
    flex-direction: column;
    align-items: center;
  }

  .header__nav {
    margin-top: 20px;
  }

  .mt-rushmore {
    height: 300px;
  }

  .face-slot {
    width: 25%;
    height: 25%;
  }

  .timer {
    top: auto;
    bottom: 20px;
    right: 20px;
  }

  .popular-answers {
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .header__logo {
    font-size: 2rem;
  }

  .header__nav {
    flex-direction: column;
    gap: 10px;
  }

  .mt-rushmore {
    height: 200px;
  }

  .face-slot {
    width: 30%;
    height: 20%;
  }

  .answer-input,
  .submit-button {
    font-size: 0.9rem;
    padding: 10px;
  }

  .popular-answer {
    flex-direction: column;
    align-items: flex-start;
  }

  .popular-answer__count {
    margin-top: 10px;
  }
}

.particle-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.particle {
  position: absolute;
  background: var(--color-primary);
  border-radius: 50%;
  opacity: 0.5;
}

@keyframes float-particle {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.particle:nth-child(odd) {
  animation: float-particle 4s infinite;
}

.particle:nth-child(even) {
  animation: float-particle 6s infinite reverse;
}

.achievement {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.achievement.show {
  opacity: 1;
  transform: translateX(-50%) translateY(-20px);
}

.achievement__icon {
  font-size: 2rem;
  margin-right: 15px;
  color: var(--color-tertiary);
}

.achievement__text {
  font-size: 1rem;
  color: var(--color-text);
}

/* Advanced Game Elements and Immersive Effects */

@keyframes hologram {
  0% {
    opacity: 0.5;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.5;
    transform: scale(0.8);
  }
}

.hologram-effect {
  position: relative;
  overflow: hidden;
}

.hologram-effect::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: linear-gradient(to bottom, transparent, var(--color-primary), transparent);
  animation: hologram 3s infinite;
  transform: rotate(45deg);
}

.leaderboard {
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid var(--color-primary);
  border-radius: 15px;
  padding: 20px;
  margin-top: 50px;
  position: relative;
  overflow: hidden;
}

.leaderboard__title {
  font-family: var(--font-display);
  font-size: 2rem;
  color: var(--color-tertiary);
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.leaderboard__list {
  list-style-type: none;
  padding: 0;
}

.leaderboard__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.leaderboard__item:hover {
  transform: scale(1.05);
  background: rgba(0, 255, 255, 0.1);
}

.leaderboard__rank {
  font-size: 1.2rem;
  color: var(--color-primary);
  width: 30px;
  text-align: center;
}

.leaderboard__username {
  flex-grow: 1;
  padding: 0 10px;
  color: var(--color-text);
}

.leaderboard__score {
  font-family: var(--font-display);
  font-size: 1.2rem;
  color: var(--color-secondary);
}

@keyframes radar-scan {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.radar-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  opacity: 0.5;
}

.radar-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, transparent, var(--color-primary));
  transform-origin: right center;
  animation: radar-scan 4s linear infinite;
}

.daily-challenge {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}

.daily-challenge__title {
  font-family: var(--font-display);
  font-size: 1.5rem;
  color: var(--color-tertiary);
  margin-bottom: 10px;
}

.daily-challenge__description {
  color: var(--color-text);
  margin-bottom: 15px;
}

.daily-challenge__reward {
  display: inline-block;
  background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  color: var(--color-background);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typing-effect {
  overflow: hidden;
  border-right: 2px solid var(--color-primary);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--color-primary);
  }
}

.user-profile {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 15px;
  margin-top: 20px;
}

.user-profile__avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--color-primary);
  margin-right: 15px;
  position: relative;
  overflow: hidden;
}

.user-profile__avatar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.3));
}

.user-profile__info {
  flex-grow: 1;
}

.user-profile__name {
  font-size: 1.2rem;
  color: var(--color-text);
  margin-bottom: 5px;
}

.user-profile__level {
  font-size: 0.9rem;
  color: var(--color-secondary);
}

.user-profile__xp-bar {
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2.5px;
  margin-top: 5px;
  overflow: hidden;
}

.user-profile__xp-progress {
  height: 100%;
  background: linear-gradient(90deg, var(--color-primary), var(--color-secondary));
  width: 0;
  transition: width 0.5s ease;
}

/* Advanced 3D and Interactive Elements */

.three-js-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.3;
}

.mini-game {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 200px;
  height: 200px;
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.mini-game__title {
  font-family: var(--font-display);
  font-size: 1rem;
  color: var(--color-tertiary);
  text-align: center;
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.5);
}

.mini-game__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
}

.easter-egg {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.easter-egg:hover {
  opacity: 1;
}

@keyframes level-up {
  0% {
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    transform: scale(1.2);
    filter: brightness(1.5);
  }
  100% {
    transform: scale(1);
    filter: brightness(1);
  }
}

.level-up-animation {
  animation: level-up 1s ease-in-out;
}

.parallax-section {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-out;
}

/* Final Touches and Advanced Interactions */

.game-board {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .game-board {
    grid-template-columns: repeat(4, 1fr);
  }
}

.game-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.game-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 255, 255, 0.3);
}

.game-card__icon {
  font-size: 2rem;
  color: var(--color-secondary);
  margin-bottom: 10px;
}

.game-card__title {
  font-family: var(--font-display);
  color: var(--color-text);
  margin-bottom: 5px;
}

.game-card__description {
  font-size: 0.9rem;
  color: var(--color-text);
  opacity: 0.7;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.modal.active {
  opacity: 1;
  visibility: visible;
}

.modal__content {
  background: var(--color-background);
  border: 2px solid var(--color-primary);
  border-radius: 15px;
  padding: 30px;
  max-width: 500px;
  width: 90%;
  transform: scale(0.7);
  transition: all 0.3s ease;
}

.modal.active .modal__content {
  transform: scale(1);
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: var(--color-text);
  cursor: pointer;
}

.confetti {
  position: fixed;
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  opacity: 0;
}

@keyframes confetti-fall {
  0% {
    transform: translateY(-100vh) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(720deg);
    opacity: 0;
  }
}

.voice-command {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: var(--color-primary);
  color: var(--color-background);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.voice-command:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px var(--color-primary);
}

.voice-command.listening {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(0, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 255, 255, 0);
  }
}

.gesture-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: none;
}

.gesture-area.active {
  display: block;
}

.gesture-trail {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-tertiary);
  opacity: 0.7;
  transition: all 0.1s ease;
}

.accessibility-controls {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.accessibility-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--color-primary);
  color: var(--color-text);
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.accessibility-button:hover {
  background: var(--color-primary);
  color: var(--color-background);
}

.high-contrast {
  --color-background: #000000;
  --color-text: #ffffff;
  --color-primary: #00ff00;
  --color-secondary: #ff00ff;
  --color-tertiary: #ffff00;
}

.larger-text {
  font-size: 120%;
}

.reduced-motion {
  transition: none !important;
  animation: none !important;
}
